import React from "react"
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionUpdate from "../../components/sections/VideosSectionUpdate";

const CommandPackagesVideosPage = () => (
  <MainContainer>
    <HeadDataNew 
      title='Command Packages Videos Page' 
      description='A list of Command Access Command Packages videos.' 
    />

    <VideosSectionUpdate 
      title="Command Packages"
      category="Command Packages"
    />
  </MainContainer>
)

export default CommandPackagesVideosPage